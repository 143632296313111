import axios, { AxiosResponse } from 'axios';
import { ProjectWithRequests, AssignRequestProject, UnassignRequestFromProject } from '../models/projectsModel';
import developmentEnv from '../common/developmentEnv.constants';

const endpoint = '/project';
const baseURL = process.env.REACT_APP_ENVIRONMENT === 'production'
  ? process.env.REACT_APP_REQUEST_API as string + endpoint
  : developmentEnv.requestApiDev + endpoint;

const assignRequestToProject = async (assignedRequest: AssignRequestProject) => {
  const res: AxiosResponse = await axios.post<AssignRequestProject>(baseURL + `/assign`, assignedRequest);
  return res.data;
}

const unassignRequestToProject = async (unAssignedRequest: UnassignRequestFromProject) => {
  const res: AxiosResponse = await axios.post<UnassignRequestFromProject>(baseURL + `/unassign`, unAssignedRequest);
  return res.data;
}

const getAllProjects = async () => {
  const res: AxiosResponse = await axios.get<ProjectWithRequests[]>(baseURL + `/requests/`);
  return res.data;
}

const getBioBankProjects = async (companyId: string) => {
  const res: AxiosResponse = await axios.get<ProjectWithRequests[]>(baseURL + `/requests/biobank/${companyId}`);
  return res.data;
}

const getBioBankHandlerProjects = async (userId: string) => {
  const res: AxiosResponse = await axios.get<ProjectWithRequests[]>(baseURL + `/requests/handler/${userId}`);
  return res.data;
}

export default {
  assignRequestToProject,
  unassignRequestToProject,
  getAllProjects,
  getBioBankProjects,
  getBioBankHandlerProjects,
}
