// React Libraries
import React, { useEffect, useState } from 'react'
import useDebounce from "../../../customHooks/useDebounce";

import {
  Select as CRSelect,
  MultiValue,
  SingleValue,
} from "chakra-react-select";

// Types/Data Models
import { OnChangeEvent } from '../../../models/miscModels';
import {
  AccessFeasibilityDetail,
  ProjectWithRequests,
} from "../../../models/projectsModel";

import { filterProjectsAndRequests } from './filterProjectsAndRequests';
import {
  ActionMeta,
  typeFilterOption,
  allTypeFilterOptions,
  allStateFilterOptions,
  Option,
} from './types';

// UI Libraries
import {
  FormControl,
  Input,
} from "@chakra-ui/react"

import { Box } from '@chakra-ui/layout';

// CSS Styles
import { GREY_BLUE } from '../../../common/style.constants';

const DELAY = 1000;


const SearchWithFilters = ({
    allProjects,
    setFilteredProjects,
    setFilteredFeasibilityRequests,
    setFilteredAccessRequests,
  }:{
    allProjects: ProjectWithRequests[],
    setFilteredProjects:  React.Dispatch<React.SetStateAction<ProjectWithRequests[]>>,
    setFilteredFeasibilityRequests:  React.Dispatch<React.SetStateAction<AccessFeasibilityDetail[]>>,
    setFilteredAccessRequests:  React.Dispatch<React.SetStateAction<AccessFeasibilityDetail[]>>,
  }):JSX.Element => {

  // Text search
  const [searchTermEvent, setSearchTermEvent]: [OnChangeEvent | undefined, (event: OnChangeEvent) => void] = useState();
  useDebounce(() => searchTermEvent && handleSearchTermChange(searchTermEvent), [searchTermEvent], DELAY);

  const [searchValue, setSearchValue] = useState<string>(sessionStorage.getItem('searchValue') || '');

  const handleSearchTermChange = (event) => {
    setSearchValue(event.target.value);
    sessionStorage.setItem('searchValue', event.target.value);
  }

  // State filter
  const initialStateFilterOptions: Option[] = [];
  const [currentStateFilterOptions, setCurrentStateFilterOptions] = useState<MultiValue<Option>>(
    JSON.parse(
      sessionStorage.getItem('currentStateFilterOptions')
      || JSON.stringify(initialStateFilterOptions)
    )
  );

  const [stateActionMeta, setStateActionMeta]: [ActionMeta | undefined, (actionMeta: ActionMeta) => void] = useState();
  useDebounce(() => {
    sessionStorage.setItem('currentStateFilterOptions', JSON.stringify(currentStateFilterOptions));
    return stateActionMeta && handleProjectRequestSearch()
  }, [currentStateFilterOptions], DELAY);

  // Type filter
  const [currentTypeFilterOption, setCurrentTypeFilterOption] = useState<SingleValue<Option>>(
    JSON.parse(
      sessionStorage.getItem('currentTypeFilterOption')
      || JSON.stringify(typeFilterOption.All)
    )
  );

  const [typeActionMeta, setTypeActionMeta]: [ActionMeta | undefined, (actionMeta: ActionMeta) => void] = useState();
  useDebounce(() => {
    sessionStorage.setItem('currentTypeFilterOption', JSON.stringify(currentTypeFilterOption));
    return typeActionMeta && handleProjectRequestSearch();
  }, [currentTypeFilterOption], DELAY);

  useEffect(() => {
    handleProjectRequestSearch();
  }, [searchValue, allProjects])

  /**
   *
   */
  const handleProjectRequestSearch = () => {
    filterProjectsAndRequests(
      allProjects,
      searchValue,
      currentStateFilterOptions,
      currentTypeFilterOption,
      setFilteredProjects,
      setFilteredFeasibilityRequests,
      setFilteredAccessRequests,
    );
  }

  return (
    <Box paddingStart="16px" paddingEnd="16px" paddingTop="8px" paddingBottom="8px" borderBottom={`1px solid ${GREY_BLUE}`}>
      <div>
        <FormControl id="search">
          <Input
            borderRadius={'0px'}
            type="text"
            defaultValue={searchValue}
            placeholder="Search"
              onChange={(e) => {
                setSearchTermEvent(e);
              }
            }/>
        </FormControl>
      </div>

      <div>
        <CRSelect
          defaultValue={currentTypeFilterOption}
          onChange={
            (option, actionMeta) => {
              setCurrentTypeFilterOption(option);
              setTypeActionMeta(actionMeta);
            }
          }
          options={allTypeFilterOptions}
        />
      </div>

      <div>
        <CRSelect
          defaultValue={currentStateFilterOptions}
          placeholder={'Filter by state'}
          isMulti={true}
          options={allStateFilterOptions}
          onChange={
            (valueObjects, actionMeta) => {
              setCurrentStateFilterOptions(valueObjects);
              setStateActionMeta(actionMeta);
            }
          }
        />
      </div>
    </Box>
  );
};

export default SearchWithFilters;
