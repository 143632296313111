import axios from 'axios';
import {auth0AudiencePlatform, auth0AudienceRequest} from "../index";

const requestApiUrl = process.env.REACT_APP_REQUEST_API;
const platformApiURl = process.env.REACT_APP_PLATFORM_API;

const authTokens = {}

const setAuthToken = (audience: string, token: string): void => {
  authTokens[audience] = token;
};

const getAuthToken = (audience?: string): string | undefined => {
  return audience && authTokens[audience];
};

const getAudienceFromUrl = (url?: string): string | undefined => {
  if (!url) return;

  if (platformApiURl && url.startsWith(platformApiURl)) {
    return auth0AudiencePlatform;
  }
  if (requestApiUrl && url.startsWith(requestApiUrl)) {
    return auth0AudienceRequest;
  }
};

axios.interceptors.request.use((config) => {
  const token = getAuthToken(getAudienceFromUrl(config.url));
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
},
function(error) {
  return Promise.reject(error);
});

export default setAuthToken;
export { getAuthToken }
