import {
  ProjectWithRequests,
} from "../../../models/projectsModel";

import {
  typeFilterOption,
} from './types';

/**
 *
 */
export const filterProjectsAndRequests = (
  allProjects,
  searchValue,
  currentStateFilterOptions,
  currentTypeFilterOption,
  setFilteredProjects,
  setFilteredFeasibilityRequests,
  setFilteredAccessRequests,
) => {
  const typeFilter = currentTypeFilterOption.value;

  // We don't want to modify the existing objects. So we make a copy.
  const allProjectsCopy = JSON.parse(JSON.stringify(allProjects))

  if (typeFilter === typeFilterOption.Access.value) {
    // We don't want to use feasibility requests for the project search
    allProjectsCopy.forEach(p => p.feasibilityDetail = [])
  } else if (typeFilter === typeFilterOption.Feasibility.value) {
    // We don't want to use access requests for the project search
    allProjectsCopy.forEach(p => p.accessDetail = [])
  }

  const searchValueResult = filterBySearchValue(allProjectsCopy, searchValue)
  const stateFilterResult = typeFilter === typeFilterOption.All.value ?
    filterProjectsByState(searchValueResult, currentStateFilterOptions) :
    filterRequestsByState(searchValueResult, currentStateFilterOptions);

  // We call all the provided functions with either filtered values or empty array.
  // This is dependant on has the user selected request type filter. If type filter
  // is selected, then we set only selected type requests. If "All" is selected, we
  // return the filtered projects with all requests associated with it.
  const filteredProjects = typeFilter === typeFilterOption.All.value ?
    stateFilterResult : []
  const filteredAccessRequests = typeFilter === typeFilterOption.Access.value ?
    stateFilterResult : []
  const filteredFeasibilityRequests = typeFilter === typeFilterOption.Feasibility.value ?
    stateFilterResult : []

  setFilteredProjects(filteredProjects);
  setFilteredFeasibilityRequests(filteredFeasibilityRequests);
  setFilteredAccessRequests(filteredAccessRequests);
}

function filterBySearchValue(allProjects, searchValue) {
  const searchValueResult: ProjectWithRequests[] = allProjects.filter(p => {
    try {
      let searchFrom = `
        ${p.name || ''}
        ${p.projectCode || ''}
        ${p.projectCode || ''}
        ${p.projectCoordinator?.firstName || ''}
        ${p.projectCoordinator?.lastName || ''}
      `;

      if (p.accessDetail) {
        searchFrom += createSearchStringFromRequests(p.accessDetail)
      }

      if (p.feasibilityDetail) {
        searchFrom += createSearchStringFromRequests(p.feasibilityDetail)
      }

      // Let's split the words to an array so that a search such as "firstname lastname" will work.
      // Without this the search will return nothing. This is because the searchFrom string is multi line.
      // As an added benefit this will return a match no matter what order the names typed are in
      const searchValues: string[] = searchValue.split(' ');
      return searchValues.some(searchVal => searchFrom.toLocaleLowerCase().includes(searchVal.toLocaleLowerCase()));
    } catch (error) {
      console.log("Error while filtering requests with search string:", error);
    }
  });

  return searchValueResult;
}

function createSearchStringFromRequests(requests) {
  let searchString = ""

  for (const request of requests) {
    searchString += `
      ${request.projectName || ''}
      ${request.organisationName || ''}
      ${request.projectSubCode || ''}
    `;

    if (Array.isArray(request.contactDetail)) {
      for (const c of request.contactDetail) {
        searchString += `
          ${c.firstName || ''}
          ${c.lastName || ''}
        `;
      }
    }
  }

  return searchString
}

function filterProjectsByState(projects, stateOptions) {
  const allowedStates = stateOptions.map(stateOption => stateOption.value)

  if (allowedStates.length > 0) {
    const stateFilterResult: ProjectWithRequests[] = projects.filter(p => {
      try {
        const hasFeasibilityRequestWithAllowedState =
          p.feasibilityDetail?.some((r) => allowedStates.includes(r.state.toLowerCase()));

        const hasAccessRequestWithAllowedState =
          p.accessDetail?.some((r) => allowedStates.includes(r.state.toLowerCase()));

        return hasFeasibilityRequestWithAllowedState || hasAccessRequestWithAllowedState;
      } catch (error) {
        console.log("Error while filtering requests with selected states:", error)
      }
    });

    return stateFilterResult;
  } else {
    return projects
  }
}

function filterRequestsByState(projects, stateOptions) {
  const requests = projects.map(p => p.accessDetail.concat(p.feasibilityDetail))

  // Requests is list of lists, so we need to flatten it.
  const requestsFlatten = requests.flat()

  const allowedStates = stateOptions.map(stateOption => stateOption.value)

  if (allowedStates.length > 0) {
    return requestsFlatten.filter(r => allowedStates.includes(r.state.toLocaleLowerCase()))
  } else {
    return requestsFlatten
  }
}
