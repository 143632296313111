export interface Option {
  label: string,
  value: string,
}

export interface ActionMeta {
  action: string;
  name?: string | undefined;
  removedValue?: {
    label: string,
    value: string,
  } | undefined;

  option?: {
    label: string,
    value: string,
  } | undefined;
}

export const states = [
  'assigned',
  'completed',
  'created',
  'decided',
  'evaluation',
  'hold',
  'ongoing',
  'refine',
  'reported',
  'responded',
  'returned',
  'saved',
  'signed',
  'submitted',
  'transfer',
];

export const allStateFilterOptions = states.map(state => ({
  label: state,
  value: state,
}));

export const typeFilterOption = {
  All: {
    label: 'All request types',
    value: 'ALL',
  },
  Feasibility: {
    label: 'Feasibility requests',
    value: 'FEASIBILITY',
  },
  Access: {
    label: 'Access requests',
    value: 'ACCESS',
  },
};

export const allTypeFilterOptions = [
  typeFilterOption.All,
  typeFilterOption.Feasibility,
  typeFilterOption.Access,
];
