import { useEffect } from 'react';

const useDebouncedEffect = (effect: () => unknown, deps: Array<unknown>, delay: number): void => {
    useEffect(() => {
        const handler = setTimeout(() => effect(), delay);

        return () => clearTimeout(handler);
    }, [...(deps || []), delay]);
};

export default useDebouncedEffect;
