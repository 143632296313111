export enum REQUEST {
  ACCESS = 'access',
  FEASIBILITY = 'feasibility'
}

export enum CONTACTYPE {
    person = 'person',
    organization = 'organization',
    investigator = 'investigator',
    pathologist = 'pathologist',
    invoice = 'invoice',
    other = 'other',
}

export enum BIOBANK {
    AURIA = "auria_biobank",
    HELSINKI = "helsinki_biobank",
    EASTERN_FINLAND = "biobank_of_eastern_finland",
    CENTRAL_FINLAND = "central_finland_biobank",
    NORTHERN_FINLAND = "northern_finland_biobank_borealis",
    TAMPERE = "tampere_biobank",
    THL = "thl_biobank",
    ARCTIC = "arctic_biobank",
}

export enum FEASIBILITY_REQUEST_STATE {
    saved = 'Saved',
    submitted = 'Submitted',
    refine = 'Refine',
    created = 'Created',
    assigned = 'Assigned',
    ongoing = 'Ongoing',
    responded = 'Responded',
    reported = 'Reported',
    hold = 'Hold',
}

export enum ACCESS_REQUEST_STATE {
    saved = 'Saved',
    submitted = 'Submitted',
    refine = 'Refine',
    created = 'Created',
    assigned = 'Assigned',
    ongoing = 'Ongoing',
    responded = 'Responded',
    reported = 'Reported',
    hold = 'Hold',
    evaluation = 'Evaluation',
    decided = 'Decided',
    signed = 'Signed',
    transfer = 'Transfer',
    completed = 'Completed',
    returned = 'Returned',
}

export const REQUEST_FORMS_V1_DEADLINE = process.env.REACT_APP_REQUEST_FORMS_V1_DEADLINE_DATE as string || '2022-12-10';

export const ICD10_URL = process.env.REACT_APP_ICD10_URL as string || 'https://koodistopalvelu.kanta.fi/codeserver/pages/publication-view-page.xhtml?distributionKey=12822&versionKey=58&returnLink=fromVersionPublicationList';

export const INVOICING_METHOD_PAPER = 'paper';
export const INVOICING_METHOD_EMAIL = 'email';
export const INVOICING_METHOD_ELECTRONIC = 'electronic';