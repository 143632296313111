import axios, { AxiosResponse } from 'axios';
import { Projects } from '../models/projectsModel';

const baseURL = process.env.REACT_APP_PLATFORM_API as string;

const getAllBioBanks = async () => {
  const res: AxiosResponse = await axios.get(baseURL + '/api/organisation?organisationCategory=service');
  return res.data;
}

const getBioBankHandlersOfBioBank = async (id: string) => {
  const res: AxiosResponse = await axios.get(baseURL + `/api/user/?organisationId=${id}`);
  return res.data;
}

const getCurrentUser = async (id: string, ) => {
  const res: AxiosResponse = await axios.get(baseURL + `/api/user/${id}`);
  return res.data;
}

const getAllUsers = async () => {
  const res: AxiosResponse = await axios.get(baseURL + `/api/user`);
  return res.data;
}

const getAllProjects = async () => {
  const res: AxiosResponse = await axios.get<Projects>(baseURL + `/api/project`);
  return res.data;
}

const getOrganisationProjects = async (organisationId) => {
  // Note: This error handling is only needed for migrated requests that
  // don't necessarily have an organisation id. All new ones should have
  // it set.
  let res: AxiosResponse;
  if (organisationId) {
    res = await axios.get<Projects>(
      baseURL + `/api/project?organisationId=${organisationId}`,
    );
  } else {
    console.warn('No organisation id found; Fetching all projects in system');
    res = await axios.get<Projects>(
      baseURL + `/api/project`,
    );
  }

  return res.data;
}

const getOrganisations = async () => {
  const res: AxiosResponse = await axios.get<Projects>(baseURL + `/api/organisation`);
  return res.data;
}

const getOrganisationById = async (id: string) => {
  const res: AxiosResponse = await axios.get<Projects>(baseURL + `/api/organisation/${id}`);
  return res.data;
}

const getOrganisationUsers = async (organisationId: string) => {
  const res: AxiosResponse = await axios.get<Projects>(baseURL + `/api/user?organisationId=${organisationId}`);
  return res.data;
}

export default {
  getAllBioBanks,
  getCurrentUser,
  getAllUsers,
  getBioBankHandlersOfBioBank,
  getAllProjects,
  getOrganisationProjects,
  getOrganisations,
  getOrganisationById,
  getOrganisationUsers,
}
