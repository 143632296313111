// React Libraries
import React from 'react'

// Components

// Types/Data Models

// API Services

// CSS Styles

// UI Libraries
import { Box } from '@chakra-ui/layout';

// Assets

export type WrapperVariant = 'small' | 'regular';


const Wrapper = ({
    variant,
    children,
  }:{
    variant: WrapperVariant,
    children: React.ReactNode
  }):JSX.Element => {


  return (
    <Box maxW={variant === 'regular' ? "800px" : "400px"} w="100%" mt={8} mx="auto">
      {children}
    </Box>
  );
};

export default Wrapper;
