export enum ROLES {
  FINBB_ADM = "finbb-admin",
  FINBB_COR = "finbb-coordinator",
  FINBB_REP = "finbb-reporter",
  BB_COR = "organisation-coordinator",
  BB_HDLR = "organisation-editor",
  BB_REP = "organisation-reporter",
  BB_STEERING = "organisation-steering-group",
  BB_RELATED_STEERING_GROUP = "related-biobank-steering-group",
}

export const PERMISSIONS = {
  UNARCHIVE_ANY_REQUEST: "unarchive:any-request",
  ARCHIVE_ANY_REQUEST: "archive:any-request",
}

