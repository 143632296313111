// React Libraries
import React from 'react'
import { Link } from 'react-router-dom';

// Components

// Types/Data Models
import { HandleActiveResourceFunction } from '../../../models/miscModels';

// API Services

// CSS Styles
import { GREY_BLUE } from '../../../common/style.constants';

// UI Libraries
import { Box, Button, Tooltip } from '@chakra-ui/react';
import { AccessFeasibilityDetail } from '../../../models/projectsModel';

// Assets
import {RequestIdAndType} from "../../../models/resources";


// eslint-disable-next-line no-empty-pattern
const RequestMenuItem = ({
    request,
    handleActiveResource,
    requestType,
    requestIdAndType,
    showProjectSubCode,
  }:{
    request: AccessFeasibilityDetail,
    handleActiveResource: HandleActiveResourceFunction,
    requestType: string,
    requestIdAndType: RequestIdAndType,
    showProjectSubCode?: boolean,
  }):JSX.Element => {

  let requestDisplayName = request.projectName;
  if (showProjectSubCode) {
    requestDisplayName = request.projectSubCode + ' ' + requestDisplayName;
  }

  if (requestDisplayName.length >= 50) {
    requestDisplayName = requestDisplayName.substring(0, 49) + '…';
  }

  return (
    <Box ml={4} borderLeft={`1px solid ${GREY_BLUE}`}>
      <Link className="customLink"
        to={`/view/${requestType}-request/${request.id}`}
        >
        <Button
          fontWeight={'normal'}
          _active={{fontWeight: 'bold'}}
          _selected={{bg: "tomato",}}
          backgroundColor={'transparent'}
          pl={4} cursor={'pointer'}
          onClick={() => {
            handleActiveResource(request, requestType, requestIdAndType)
          }}>
          <Tooltip label={request.projectName}>
            {requestDisplayName}
          </Tooltip>
        </Button>
      </Link>
    </Box>
  );
};

export default RequestMenuItem;
