import { TranslationType } from '../i18n.types';

const english: TranslationType = {
  ApplicationNames: {
    cohorts: 'Cohorts',
    platform: 'Platform',
    requests: 'Requests',
    recruit: 'Recruit',
    offertool: 'Offertool',
    researcherUi: 'Researcher UI',
  },

  Routes: {
    privacyPolicy: 'Privacy policy',
  },
};

export default english;
