import { TranslationType } from '../i18n.types';

const finnish: TranslationType = {
  ApplicationNames: {
    cohorts: 'Kohortit',
    platform: 'Alusta',
    requests: 'Pyynnöt',
    recruit: 'Rekry',
    offertool: 'Tarjoustyökalu',
    researcherUi: 'Tutkijan käyttöliittymä',
  },

  Routes: {
    privacyPolicy: 'Tietosuojaseloste',
  },
};

export default finnish;
