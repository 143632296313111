import { Global } from "@emotion/react"

const Fonts = () => (
  // eslint-disable-next-line react/react-in-jsx-scope
  <Global
    styles={`
    @font-face {
      font-family: 'Mukta-Bold';
      src: local('Mukta-Bold'), url(./fonts/Mukta-Bold.ttf) format('truetype');
    }

    @font-face {
      font-family: 'Mukta-ExtraBold';
      src: local('Mukta-ExtraBold'), url(./fonts/Mukta-ExtraBold.ttf) format('truetype');
    }

    @font-face {
      font-family: 'Mukta-ExtraLight';
      src: local('Mukta-ExtraLight'), url(./fonts/Mukta-ExtraLight.ttf) format('truetype');
    }

    @font-face {
      font-family: 'Mukta-Medium';
      src: local('Mukta-Medium'), url(./fonts/Mukta-Medium.ttf) format('truetype');
    }

    @font-face {
      font-family: 'Mukta-Regular';
      src: local('Mukta-Regular'), url(./fonts/Mukta-Regular.ttf) format('truetype');
    }

    @font-face {
      font-family: 'Mukta-SemiBold';
      src: local('Mukta-SemiBold'), url(./fonts/Mukta-SemiBold.ttf) format('truetype');
    }
      `}
  />
)

export default Fonts;
