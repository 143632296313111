// React Libraries
import React from 'react'

// Components
import RequestMenuItem from './RequestMenuItem';

// Types/Data Models
import { SetActiveResources } from '../../../models/resources';

import { AccessFeasibilityDetail, ProjectWithRequests } from '../../../models/projectsModel';

// API Services

// CSS Styles

// UI Libraries
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  Box,
  AccordionPanel,
  Text,
  Tooltip
  } from "@chakra-ui/react"
import { GREY_BLUE, LIGHT_BLUE } from '../../../common/style.constants';

// Assets

const customProjectListStyle ={
  accordionItem: {
    borderTop: 'none',
  }
}

// eslint-disable-next-line no-empty-pattern
const ProjectList = ({
    setActiveResource,
    allProjects,
    setSelectedSidebar,

  }:{
    setActiveResource: SetActiveResources,
    allProjects: ProjectWithRequests[],
    setSelectedSidebar: React.Dispatch<React.SetStateAction<string>>,
  }):JSX.Element => {

  /*
    handleActiveResource sets activeRoute for error message if getting request triggers error.
  */
  const handleActiveResource =  (request: AccessFeasibilityDetail) => {
    const activeRoute = [request.projectName, request.projectName];

    // Just because for some reason the UpdateResponse sidebar doesn't
    // get reset when switching requests.
    setSelectedSidebar('none');

    setActiveResource(activeRoute);
  }

  return (
    <Box
      overflow={'auto'}
      maxHeight={'50vh'}>
      <Accordion allowMultiple>
        {allProjects.map((p) => {
        return (
          <div key={p.id}>
            <AccordionItem style={customProjectListStyle.accordionItem}>
              <h2>
                <AccordionButton key={p.id} _expanded={{bg: GREY_BLUE}}>
                  {/*
                    Trimming too long project names and providing hover tooltip that displays full name
                  */}
                  <Tooltip label={p.name}>
                    {(p.name.length <= 60)
                      ? <Box flex="1" textAlign="left"><i>{p.projectCode}</i> - {p.name}</Box>
                      : <Box flex="1" textAlign="left"><i>{p.projectCode}</i> - {p.name.substring(0,59) + '…'}</Box>
                    }
                  </Tooltip>
                  <AccordionIcon/>
                </AccordionButton>
              </h2>
              <AccordionPanel>
                {p.feasibilityDetail?.length > 0 && <Text ml={4} key={p.id} color={LIGHT_BLUE}>Feasibility Studies:</Text>}
                {p.feasibilityDetail?.map((r: AccessFeasibilityDetail) => {
                  return (
                    <RequestMenuItem key={r.id} requestType='feasibility' handleActiveResource={handleActiveResource} request={r} requestIdAndType={{id: r.id, type: 'feasibility'}} />
                  )
                })}
                {p.accessDetail?.length > 0 && <Text ml={4} key={p.referenceId}  color={LIGHT_BLUE}>Access Requests:</Text>}
                {p.accessDetail?.map((r: AccessFeasibilityDetail) => {
                  return (
                    <RequestMenuItem key={r.id} requestType='access' handleActiveResource={handleActiveResource} request={r} requestIdAndType={{id: r.id, type: 'access'}}/>
                  )
                })}
              </AccordionPanel>
            </AccordionItem>
          </div>
        )
      })}
      </Accordion>
    </Box>
  );
};

export default ProjectList;
