// React Libraries
import React from 'react'
import { useTranslation } from 'react-i18next';

// Components
import Wrapper from '../../common/Wrapper';

// Types/Data Models

// API Services
import { useAuth0 } from '@auth0/auth0-react';

// CSS Styles
import { BLUE, DARK_BLUE, HEADER_SIZE } from '../../common/style.constants';

// UI Libraries
import { Box, Flex, Text, Link } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';

// Assets
import fingeniousLogo from '../../assets/logo_blue.svg';


const loginWrapperStyle = {
  customWrap: {
    boxShadow: "rgb(196 199 204) 0px 0px 2px",
    height: "400px"
  },
  cancelButton: {
    backgroundColor: '#DC3544',
    color: 'white',
  },
  logo: {
    width: '200px',
    height: 'auto',
  },
}


const Login = ():JSX.Element => {
  const { t } = useTranslation();

  const {
    loginWithPopup,
  } = useAuth0();

  const platformSignUp = process.env.REACT_APP_USER_REGISTER_URL as string;
  const platformPrivacyPolicy = process.env.REACT_APP_PLATFORM_URL + 'privacy-policy' as string;

  return (
    <Wrapper variant={'small'}>
      <Flex mt={200} padding={4} flexDirection={'column'} alignItems={'center'}>
        <Flex w={300} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Box>
            <img src={`${fingeniousLogo}`} alt='logo' style={loginWrapperStyle.logo}/>
          </Box>

          <Box borderLeft={`1px solid ${DARK_BLUE}`} h={'32px'}></Box>

          <Box>
            <Text borderRadius={'5px'} backgroundColor={DARK_BLUE} color={'white'} w={'75px'} textAlign={'center'}>Requests</Text>
          </Box>
        </Flex>

        <Text mt={5} fontSize={HEADER_SIZE} color={BLUE} textAlign={'center'}>Welcome!</Text>

        <Box textAlign={'center'} maxWidth={"300px"} mt={5} ml={'auto'} mr={'auto'}>Welcome to the Fingenious Requests application</Box>
        <Flex mt={10} flexDirection={'column'} width={'100%'}>
          <Button mb={2} colorScheme={'blue'} backgroundColor={DARK_BLUE} onClick={() => loginWithPopup({ ui_locales: "en" })}>Log in</Button>
          <Link href={platformSignUp}>
            <Button width={'100%'} colorScheme={'gray'} >Sign Up</Button>
          </Link>
        </Flex>

        <Box alignItems={'center'} mt={25}>
          <Link href={platformPrivacyPolicy}>
            {t('Routes.privacyPolicy')}
          </Link>
        </Box>
      </Flex>
    </Wrapper>
  );
};

export default Login;
