// React Libraries
import React, { useContext } from 'react'

// Components

// Types/Data Models

// API Services

// CSS Styles
import { BLUE, BOX_HEIGHT, LIGHT_BLUE, REGULAR_PLUS_SIZE } from '../../../common/style.constants';

// UI Libraries
import { Flex, Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import { RoleContext } from '../../../App';
import { ROLES } from '../../../common/userPermissions.constants';
import { useHistory } from 'react-router-dom';

// Assets


const Projects = ():JSX.Element => {

  const history = useHistory();
  const currentRole = useContext(RoleContext);

  return (
    <>
      <Flex p={5} pl={4} height={BOX_HEIGHT} backgroundColor={LIGHT_BLUE} justify={"space-between"} align={"center"}>
        <Text fontSize={REGULAR_PLUS_SIZE}>Projects</Text>
        {/*
          View all requests button only visible to users with role finbb-admin, finbb-coordinator or finbb-reporter
        */}
        {(currentRole === ROLES.FINBB_COR || currentRole === ROLES.FINBB_ADM || currentRole === ROLES.FINBB_REP) &&
          <Button width={'150px'}
          justifyContent="flex-start"
          borderRadius={0}
          fontWeight={'normal'}
          colorScheme={'blue'}
          backgroundColor={BLUE}
          onClick={(() => history.push('/view/all-requests'))}
          >View All Requests</Button>
        }
      </Flex>
    </>
  );
};

export default Projects;
