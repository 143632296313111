export const SMALL_SIZE = '14px';
export const REGULAR_SIZE = '18px';
export const REGULAR_PLUS_SIZE = '20px';
export const HEADER_SIZE = '30px';
export const SUB_HEADER_SIZE = '22px';
export const BOX_HEIGHT = '66px';
export const NAVBAR_HEIGHT = '66px';
export const MAIN_TOP_MARGIN = '230px';
export const SECONDARY_TOP_MARGIN = '126px';
export const LEFT_MARGIN = '399px';
export const GREY_BLUE = '#CED8F2';
export const DARK_BLUE = '#0E276C';
export const BLUE = '#113686';
export const LIGHT_BLUE = '#B8C4E5';
export const GREY_BLACK = '#343A40';
