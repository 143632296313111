import crypto from 'crypto-random-string';
import { memo } from 'react';
import { Helmet } from 'react-helmet';
import React from 'react'

interface CspHeaderProps {
    extraStyleNonce: string
}

const CspHeader = ({ extraStyleNonce }: CspHeaderProps) => {
  const AUTH_SERVICE = process.env.REACT_APP_AUTH0_DOMAIN;
  const API_PLATFORM = process.env.REACT_APP_PLATFORM_API;
  const API_REQUEST = process.env.REACT_APP_REQUEST_API;

  const createCspString = (nonce: string) => {
    const cspRules = {
      "base-uri": ["'self'"],
      "object-src": ["'none'"],
      "script-src": ["'self'"],
      "style-src": ["'self'"],
      "frame-src": [AUTH_SERVICE],
      "connect-src": ["'self'", AUTH_SERVICE, API_PLATFORM, API_REQUEST],
      "font-src": ["'self'"],
      "manifest-src": ["'self'"],
    };

    cspRules['style-src'].push(`'nonce-${nonce}'`);
    cspRules['style-src'].push(`'nonce-${extraStyleNonce}'`);

    // These suppress some errors. ¯\_(ツ)_/¯
    cspRules['style-src'].push("'sha256-47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU='");
    cspRules['style-src'].push("'sha256-6Lr/sEFIaDCOtpRo299EWL0504mNTvA1ZBoD31eXD04='");

    // These make PDF downloads work(until Chrome internal styles change). See
    // https://bugs.chromium.org/p/chromium/issues/detail?id=271452
    cspRules['style-src'].push("'unsafe-hashes' 'sha256-C7vpsE1KLI7RuUgCprJTQZin6dWK+ccynbOx+OqjVow='");
    cspRules['style-src'].push("'sha256-tbWZ4NP1341cpcrZVDn7B3o9bt/muXgduILAnC0Zbaw='");
    cspRules['style-src'].push("'sha256-D6kpIX0A4TTsmvxfEpOpkS2C/u3VGN/Hbjf0UwftoVg='");

    const ruleStrings = Object.entries(cspRules).map((cspRule) => {
      const ruleName = cspRule[0];
      const rules = cspRule[1];
      return `${ruleName} ${rules.join(' ')}`;
    });

    return ruleStrings.join(';');
  };

  const nonce = crypto({ length: 16, type: 'url-safe' });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).__webpack_nonce__ = nonce;
  const cspString = createCspString(nonce);

  console.log(cspString);
  // CSP header disabled because it broke everything. To be fixed.
  return (
    <Helmet>
      <meta httpEquiv="Content-Security-Policy" content="" />
    </Helmet>
  );

};

export default memo(CspHeader);
