import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import React, { PropsWithChildren, ReactElement } from 'react'

type EmotionCacheProviderProps = PropsWithChildren<unknown> & {
  nonce: string
}

/*
    For reference: https://github.com/chakra-ui/chakra-ui/issues/3294#issuecomment-774389418
    Adds nonce for styles injected by ChakraUI + Emotion
*/
export const EmotionCacheProvider = ({ nonce, children }: EmotionCacheProviderProps): ReactElement => {
  const cache = createCache({ key: 'contentsecuritypolicy', nonce })
  return <CacheProvider value={cache}>{children}</CacheProvider>
}
