// React Libraries
import React, { useState } from 'react'

// Components
import Projects from './Projects/Projects';
import ProjectList from './ProjectList/ProjectList';
import RequestList from './ProjectList/RequestList';
import SearchWithFilters from '../Common/SearchWithFilters/SearchWithFilters';

// Types/Data Models
import { SetActiveResources } from '../../models/resources';
import {
  AccessFeasibilityDetail,
  ProjectWithRequests,
} from '../../models/projectsModel';

// CSS Styles
import {BOX_HEIGHT, GREY_BLUE} from '../../common/style.constants';

// UI Libraries
import { Box, Flex } from '@chakra-ui/layout';


const SideMenu = ({
    setActiveResource,
    allProjects,
    setSelectedSidebar,
  }:{
    setActiveResource: SetActiveResources,
    allProjects: ProjectWithRequests[],
    setSelectedSidebar: React.Dispatch<React.SetStateAction<string>>,
  }):JSX.Element => {

  const [filteredProjects, setFilteredProjects] = useState<ProjectWithRequests[]>(allProjects);
  const initialRequests: AccessFeasibilityDetail[] = [];
  const [filteredFeasibilityRequests, setFilteredFeasibilityRequests] = useState<AccessFeasibilityDetail[]>(initialRequests);
  const [filteredAccessRequests, setFilteredAccessRequests] = useState<AccessFeasibilityDetail[]>(initialRequests);

  return (
    <Box
      width={'400px'}
      minHeight={'100vh'}
      borderRight={`1px solid ${GREY_BLUE}`}
      position={'fixed'}
      top={BOX_HEIGHT}
      left={0}
    >
      <Flex flexDirection={'column'}>
        <Projects />

        <SearchWithFilters
          allProjects={allProjects}
          setFilteredProjects={setFilteredProjects}
          setFilteredFeasibilityRequests={setFilteredFeasibilityRequests}
          setFilteredAccessRequests={setFilteredAccessRequests}
        />

        {(filteredProjects.length > 0) && <ProjectList
          allProjects={filteredProjects}
          setActiveResource={setActiveResource}
          setSelectedSidebar={setSelectedSidebar}
        />}

        {(filteredFeasibilityRequests.length > 0) && <RequestList
          requests={filteredFeasibilityRequests}
          setActiveResource={setActiveResource}
          setSelectedSidebar={setSelectedSidebar}
          requestType={'feasibility'}
        />}

        {(filteredAccessRequests.length > 0) && <RequestList
          requests={filteredAccessRequests}
          setActiveResource={setActiveResource}
          setSelectedSidebar={setSelectedSidebar}
          requestType={'access'}
        />}
      </Flex>
    </Box>
  );
};

export default SideMenu;
