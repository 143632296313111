// React Libraries
import React from 'react'

// Components
import RequestMenuItem from './RequestMenuItem';

// UI Libraries
import { Box } from "@chakra-ui/react"

// Types/Data Models
import { SetActiveResources } from '../../../models/resources';

import { AccessFeasibilityDetail } from '../../../models/projectsModel';


const RequestList = ({
    setActiveResource,
    requests,
    setSelectedSidebar,
    requestType,
  }:{
    setActiveResource: SetActiveResources,
    requests: AccessFeasibilityDetail[],
    setSelectedSidebar: React.Dispatch<React.SetStateAction<string>>,
    requestType: string,
  }):JSX.Element => {

  /*
    handleActiveResource sets activeRoute for error message if getting request triggers error.
  */
  const handleActiveResource =  (request: AccessFeasibilityDetail) => {
    const activeRoute = [request.projectName, request.projectName];

    // Just because for some reason the UpdateResponse sidebar doesn't
    // get reset when switching requests.
    setSelectedSidebar('none');

    setActiveResource(activeRoute);
  }

  return (
    <Box
      overflow={'scroll'}
      maxHeight={'50vh'}
      maxWidth={'400px'}>
      {requests.map((r) => {
        return (
          <Box key={r.id} overflow={'hidden'}>
            <RequestMenuItem
              request={r}
              handleActiveResource={handleActiveResource}
              requestType={requestType}
              requestIdAndType={{id: r.id, type: requestType}}
              showProjectSubCode={true}
            />
          </Box>
        )
      })}
    </Box>
  );
};

export default RequestList;
