// React Libraries
import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components

// Types/Data Models

// API Services
import { useAuth0 } from '@auth0/auth0-react';

// CSS Styles
import { NAVBAR_HEIGHT, BLUE, REGULAR_PLUS_SIZE } from '../../common/style.constants';

// UI Libraries
import { Box, Flex, Link as ChakraLink } from '@chakra-ui/layout';
import { Avatar } from '@chakra-ui/avatar';
import { Button } from '@chakra-ui/button';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/menu';

// Assets
import fingeniousLogo from '../../assets/fingenious-logo-white.svg';
import { CurrentUser } from "../../models/userModel";

import { getAppLinksForRole } from './links';

// eslint-disable-next-line no-empty-pattern
const NavBar = ({
    currentUser
  }:{
    currentUser: CurrentUser
  }):JSX.Element => {
  const { t } = useTranslation();
  const { user, logout, } = useAuth0();
  const fullName = currentUser?.firstName + ' ' + currentUser?.lastName;
  const applicationLinks = getAppLinksForRole(currentUser.roles || [], t);

  const navBarCustomStyle = {
    logoTitle: {
      fontSize: REGULAR_PLUS_SIZE,
      color: 'white',
    },
    logo: {
      width: '144px',
      height: '32px',
    },
    userName: {
      color: 'white',
      marginRight: '15px',
    },
    profileImage: {
      width: '40px',
      height: '40px',
      backgroundSize: 'cover',
      borderRadius: '50%',
      backgroundImage: `url(${user?.picture})`,
    }
  }

  const handleLogout = () => {
    localStorage.clear();
    logout({ returnTo: window.location.origin })
  }

  return (
    <Box position={'fixed'} top={0} left={0} pr={3} pl={4} width={'full'} backgroundColor={BLUE} height={NAVBAR_HEIGHT} zIndex={9999}>
      <Flex height={NAVBAR_HEIGHT} alignItems={'center'} justifyContent={'space-between'} flexDirection={'row'}>
        <Flex w={800} flexDirection={'row'} alignItems={'center'}>
          <Box>
            <Link to="/" onClick={() => {localStorage.clear();}}>
              <img src={`${fingeniousLogo}`} alt='logo' style={navBarCustomStyle.logo}/>
            </Link>
          </Box>

          <Box borderLeft={'1px solid white'} ml={'16px'} mr={'20px'} h={'32px'}>

          </Box>

          <Box>
            {applicationLinks?.map((applicationLink) => {
              const color = applicationLink.text === t('ApplicationNames.requests') ? BLUE : 'white';
              const backgroundColor = applicationLink.text === t('ApplicationNames.requests') ? 'white' : BLUE;
              return (
                <ChakraLink
                  key={applicationLink.href}
                  href={applicationLink.href}
                  mr={'15px'}
                  p={'0 10px'}
                  borderRadius={'5px'}
                  backgroundColor={backgroundColor}
                  color={color}
                  textAlign={'center'}
                  textTransform={'uppercase'}
                  fontWeight={'bold'}
                  fontSize={'0.9rem'}
                >
                  {applicationLink.text}
                </ChakraLink>
              )}
            )}
          </Box>
        </Flex>

        <Flex alignItems={'center'} flexDirection={'row'}>
          <div style={navBarCustomStyle.userName}>
            {fullName}
          </div>
            <Menu>
              <MenuButton
                as={Button}
                aria-label='menu-button'
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}>
                <Avatar
                  size={'sm'}
                  name='avatar'
                  src={`${user?.picture}`}
                />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => handleLogout()}>Sign Out</MenuItem>
              </MenuList>
            </Menu>
        </Flex >
      </Flex>
    </Box>
  );
};

export default NavBar;
