interface ApplicationLinkType {
  text: string;
  href: string;
}

export const getAppLinksForRole = (
  userRoles: string[],
  t: (arg0: string) => string
): ApplicationLinkType[] => {
  const applicationLinks: ApplicationLinkType[] = [];
  if (userRoles === ['researcher']) {
    // Researcher, limited links
    [
      {
        text: t('ApplicationNames.requests') as string,
        href: `${process.env.REACT_APP_URL_RESEARCHER_UI}/requests`,
      },
      {
        text: t('ApplicationNames.cohorts') as string,
        href: process.env.REACT_APP_URL_COHORTS as string,
      },
      // The below was commented out in January of 2024 due to recruit being shutdown
      // TODO: Uncomment when recruit is started again
      /*{
        text: t('ApplicationNames.recruit') as string,
        href: process.env.REACT_APP_URL_RECRUIT as string,
      },*/
      {
        text: t('ApplicationNames.platform') as string,
        href: process.env.REACT_APP_URL_PLATFORM as string,
      },
    ].forEach((i) => {
      applicationLinks.push(i);
    });
  } else if (userRoles === undefined || userRoles.length === 0) {
    // No role, no links yet. Either user is not logged in, or not
    // approved. Only show the "Requests" link so the user knows where
    // they are.
    applicationLinks.push({
      text: t('ApplicationNames.requests') as string,
      href: process.env.REACT_APP_URL_REQUESTS as string,
    });
  } else {
    // "admin" level links
    [
      {
        text: t('ApplicationNames.requests') as string,
        href: process.env.REACT_APP_URL_REQUESTS as string,
      },
      {
        text: t('ApplicationNames.cohorts') as string,
        href: process.env.REACT_APP_URL_COHORTS as string,
      },
      // The below was commented out in January of 2024 due to recruit being shutdown
      // TODO: Uncomment when recruit is started again
      /*{
        text: t('ApplicationNames.recruit') as string,
        href: process.env.REACT_APP_URL_RECRUIT as string,
      },*/
      {
        text: t('ApplicationNames.platform') as string,
        href: process.env.REACT_APP_URL_PLATFORM as string,
      },
      /*
      {
        text: t('ApplicationNames.offertool') as string,
        href: process.env.REACT_APP_URL_OFFERTOOL as string,
      },
      {
        text: t('ApplicationNames.researcherUi') as string,
        href: process.env.REACT_APP_URL_RESEARCHER_UI as string,
      },
      */
    ].forEach((i) => {
      applicationLinks.push(i);
    });
  }

  return applicationLinks;
};
