import { ROLES } from '../common/userPermissions.constants';
import { CurrentUser } from '../models/userModel';
import { User } from '@auth0/auth0-react';

const determineRole = (currentUser: CurrentUser) : string => {
  for (let i = 0; i < currentUser.roles.length; i++) {
    if (currentUser.roles[i] === ROLES.FINBB_ADM) {
      return ROLES.FINBB_ADM;
    } else if (currentUser.roles[i] === ROLES.FINBB_COR) {
      return ROLES.FINBB_COR;
    } else if (currentUser.roles[i] === ROLES.BB_COR) {
      return ROLES.BB_COR;
    } else if (currentUser.roles[i] === ROLES.BB_HDLR) {
      return ROLES.BB_HDLR;
    } else if (currentUser.roles[i] === ROLES.FINBB_REP) {
      return ROLES.FINBB_REP;
    } else if (currentUser.roles[i] === ROLES.BB_REP) {
      return ROLES.BB_REP;
    } else if (currentUser.roles[i] === ROLES.BB_STEERING) {
      return ROLES.BB_STEERING;
    } else if (currentUser.roles[i] === ROLES.BB_RELATED_STEERING_GROUP) {
      return ROLES.BB_RELATED_STEERING_GROUP;
    }
  }
  return "";
}

const checkUserPermission = (currentUser: User, permission: string) : boolean => {
  const permissions = currentUser.permissions;
  return permissions.includes(permission);
}

export default {
  determineRole,
}

export {
  determineRole,
  checkUserPermission
}
